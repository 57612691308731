@font-face {
    font-family: "Caviar Dreams";
    src: url('/fonts/CaviarDreams.eot');
    src: url('/fonts/CaviarDreams.eot?#iefix') format('embedded-opentype'),
    url('/fonts/CaviarDreams.woff') format('woff'),
    url('/fonts/CaviarDreams.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
html {
    font-family: "Caviar Dreams";
    font-weight: normal;
    font-style: normal;
}
body{
    font-family: "Caviar Dreams";
    font-weight: normal;
    font-style: normal;
}
h3{
    margin: 0;
    padding: 0;
}
p{
    font-size: 17px;
    margin: 0;
    padding: 0;
    color: #000;
}
.signature {
    font-family: 'Bad Script', cursive;
}
.background-cover {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center !important;
}
.btn-white {
    background-color:#fff;
    color: #000;
}
.btn-white:hover{
    background-color:#ccc;
    color: #000;
}
.input-grey-light{
    background:rgba(0,0,0, 0.3);
    color: #fff;
    border: 1px solid #ccc;
}
.text-white {
    color: #fff;
}
.mar-top-10 {
    margin-top: 10px;
}
.mar-top-20 {
    margin-top: 20px;
}
.mar-top-40{
    margin-top: 50px;
}
.mar-top-50 {
    margin-top: 50px;
}
.mar-top-70 {
    margin-top: 100px;
}
.mar-top-160 {
    margin-top: 160px;
}
.navbar.navbar-light{
    border-bottom: 1px solid #ccc;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 1.0);
    font-size: 18px;
}
.flag-sm {
    width: 20px;
}
/***********/
/*HOME*/
.banner_wrapper {
    padding:5% 0;
}
.banner{
    background: rgba(0,0,0, 0.3);
    overflow: hidden;
    position: relative;
}
.banner img{
    float: left;
    margin-right: 10px;
    width: 25%;
}
.banner p{
    font-size: 20px;
    color: #fff;
    padding: 10px;
    margin: 0;
}
.banner-signature {
    position: absolute;
    bottom: -10px;
    right: 0;
}
.section-header {
    border-left: 8px solid #333;
    line-height: 8px;
    padding-left: 20px;
    margin-bottom: 20px;
}
.section-header-white {
    border-color:#fff;
}
.section-header-slogan {
    padding-left: 50px;
}
.grid a {
    overflow: hidden;
    height: 130px;
    margin-top: 20px;
}
.grid a img{
    width: 100%;
}
.grid a:hover img{
    opacity: .9;
}
.about {
    background-color: #f4f4f4;
    padding: 40px 0;
}
.numbers-wwrapper {
    text-align: center;
    margin-top: 20px;
}
.numbers-wwrapper .image-container {
    overflow: hidden;
    border-radius: 50%;
}
.numbers-wwrapper .image-container img{
    width:100%;
}
.numbers-wwrapper  .number {
    font-size: 50px;
    text-transform: uppercase;
}
.numbers-wwrapper  .number-description {
    font-size: 16px;
    height: 30px;
}
.videos {
    padding: 2% 0;
}
.videos iframe{
    height: 300px;
}
.audio-love p{
    font-size: 24px;
}
.charity {
    padding: 2% 0;
}

.testimonial {
    background-color: #f8f8f8;
    text-align: center;
    padding-top: 40px;
}
.testimonial h5 {

    color: #777;
    margin: 0 0 10px 0;
    font-size: 26px;
}
.testimonial h2 {
    color: #333;
    margin: 0 0 60px 0;
    font-size: 38px;
}
.testimonial p {
    max-width: 70%;
    margin: 0 auto;
    font-size: 17px;
    color: #555;
    line-height: 30px;
    font-weight: 500;
}
.testimonial img{
    width: 100px;
    margin: 60px auto 25px auto;
    border-radius: 50%;
}
.testimonial h4{
    font-weight: 500;
    margin-bottom: 5px;
}
.testimonial .owl-controls {
    position: absolute;
    top: calc(50% - 35px);
    width: 100%;
}
.testimonial .owl-buttons div{
    font-size: 25px;
    display: inline-block;
}
.testimonial .owl-buttons .owl-prev{
    float: left;
}
.testimonial .owl-buttons .owl-next{
    float: right;
}
/********/


.footer{

	-webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: top right !important;
  color: #fff;
  padding-top: 20px;
}

.footer a {
    color: #ddd;
    text-decoration: none;
}
.footer a:hover {
    color: #fff;
}
.footer p {
    color: #fff;
}
.footer-contacts ul ul {
	overflow: hidden;
}
.footer-contacts>ul>li {
	padding: 10px 0;
}
.footer-contacts ul ul li {
	float: left;
	width: 90%;
}
.footer-contacts ul ul .fa {
	width: 10%;
	color: #fff;
	font-size: 14px;
}
.social-btns a{
	width :30px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	border-radius: 50%;
	margin-right: 10px;
	display: inline-block;
	color: #fff !important;
	text-decoration: none;
	font-size: 16px;
}

.social-btns a:hover{
	opacity: .8;
}
.social-btns a.facebook {
	background-color: #3b5998;
}
.social-btns a.twitter {
	background-color: #00aff0;
}

.social-btns a.google-plus {
	background-color: #dd4b39;
}
.social-btns a.vk {
	background-color: #4c75a3;
}
.social-btns a.linkedin {
	background-color: #0274b3;
}

#contacts .form-control {
	background:rgba(0,0,0,.5);
	border-color: rgba(0,0,0,.5);
	color: #666;
}
#contacts .input-group-addon:first-child {
    border-color: rgba(0,0,0,.5);
    background: rgba(0,0,0,.5);
    color: #666;
}
.copyrights {
	padding: 20px;
	font-size: 14px;
	color: #000;
}

.grecaptcha-badge {
    bottom:60px !important;
    display: none;
}
.footer-contacts>div{
    padding-bottom: 20px;
}
.contacts-dark{
    background: rgba(0,0,0, 0.3);
    padding: 20px;
    margin-top: 40px;
}
.footer-icon {
    font-size: 18px;
    display: block;
    padding: 10px 0;
}
.footer-icon {
    font-size: 14px;
    display: block;
    padding: 10px 0;
}
.footer-text{
    font-size: 18px;
}
